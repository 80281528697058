.custom-tooltip {
  font-size: 1rem;
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  .arrow {
    position: absolute;
    display: block;
    width: .8em;
    height: .4em;
    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
  &.bs-tooltip-auto[x-placement^=top], &.bs-tooltip-top {
    .arrow {
      &::before {
        top: 0;
        border-width: .4em .4em 0;
        border-top-color: #4D5672;
      }
      &.light::before {
        border-top-color: #fff;
      }
    }
  }
  &.bs-tooltip-auto[x-placement^=bottom], &.bs-tooltip-bottom {
    .arrow {
      &::before {
        top: 0;
        border-width: 0 .4em .4em;
        border-bottom-color: #4D5672;
      }
      &.light::before {
        border-bottom-color: #fff;
      }
    }
  }
  &.bs-tooltip-auto[x-placement^=left], &.bs-tooltip-left {
    .arrow {
      &::before {
        right: 0;
        border-width: .4em 0 .4em .4em;
        border-left-color: #4D5672;
      }
      &.light::before {
        border-left-color: #fff;
      }
    }
  }
  &.bs-tooltip-auto[x-placement^=right], &.bs-tooltip-right {
    .arrow {
      &::before {
        right: 0;
        border-width: .4em .4em .4em 0;
        border-right-color: #4D5672;
      }
      &.light::before {
        border-right-color: #fff;
      }
    }
  }

  .custom-tooltip-inner {
    background-color: #4D5672;
    padding: 0.5em; // 1.072em 1.072em;
    color: #fff;
    border-radius: 0.321em;
    letter-spacing: 0.014em;
    box-shadow: 0px 0px 5px 1px #c3c3c391;
    .content-header {
      padding-bottom: 0.286rem;
      font-weight: 500;
      color: #3451a8;
      font-size: 1.143rem;
    }
    &.light {
      background-color: #fff;
      color: #5e5e5e;
    }
  }
}
