.insightLoader {
  top: 50%;
  transform: translateY(-50%);
}
.loaderWithNoData {
  min-height: 100px;
}

.dismissBtn, .collapseBtn {
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}

.collapseBtn {
  min-width: 3.375rem;
  text-align: left;
}

.insightsCollapser {
  max-height: 500px;
  transition: max-height 0.35s ease-in;
  &.collapsed {
    max-height: 0;
    transition: max-height 0.25s ease-out;
  }
}

.clickable {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}