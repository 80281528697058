.reportLayout {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  border-radius: 8px;
  overflow: hidden;
  .reportHeader {
    min-height: 3.25rem;
    background-color: white;
    .reportTitle {
      color: #252733;
    }
    .toggler {
      padding: 2px;
      background-color: #E0E6FF;
      border-radius: 4px;
      margin-left: 0.5rem;
      .option {
        background: transparent;
        font-size: 0.875rem;
        padding: 0.375rem 1.25rem;
        line-height: 1.25rem;
        border-radius: 4px;
        cursor: pointer;
        min-width: 5rem;
        &.active {
          background-color: #fff;
        }
      }
    }
    .reportInfo {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      color: #99A7D7;
      border: 1px solid #99A7D7;
    }
  }
  .reportBody {
    min-height: 15.625rem;
    height: calc(100% - 3.25rem);
    background-color: #fff;
      .loaderWrapper {
      background-color: #fff;
      top: 0;
      left: 0;
      z-index: 1;
      font-size: 0.875rem;
      color: #4C5862;
    }
  }
}