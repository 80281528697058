@import './styles/bootstrapOverrides';

.App {
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

@media (max-width: 992px) {
  .filterMob {
    padding-top: 10px;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.link-custom {
  color: #00249C;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    color: #001250;
    text-decoration: underline;
  }
}

.h-1px {
  height: 1px;
}

.no-wrap {
  white-space: nowrap;
}

.wrap {
  white-space: normal;
}

.fs-dot75 {
  font-size: 0.75rem;
}

.fs-dot5 {
  font-size: 0.5rem;
}

.fs-dot875 {
  font-size: 0.875rem;
}

.fs-dot975 {
  font-size: 0.975rem;
}

.fs-1 {
  font-size: 1rem;
}

.fs-1dot25 {
  font-size: 1.25rem;
}

.fs-1dot5 {
  font-size: 1.5rem;
}

.text-dodger-blue {
  color: #109CF1;
}

.text-light-grey {
  color: rgb(66, 66, 66);
}

.bs-dot25 {
  border-spacing: 0.25rem;
}

// components
.divider {
  display: block;
  height: 1px;
  background-color: rgba(0, 36, 156, 0.2);
}

// line-height
.lh-1 {
  line-height: 1rem;
}

.lh-1dot125 {
  line-height: 1.125rem;
}

.lh-1dot25 {
  line-height: 1.25rem;
}

.lh-1dot5 {
  line-height: 1.5rem;
}

.lh-1dot625 {
  line-height: 1.625rem;
}

.lh-1dot875 {
  line-height: 1.875rem;
}

// width
.w-1 {
  width: 1rem;
}

.vw-100 {
  width: 100vw;
}

// height
.h-1 {
  height: 1rem;
}

// outline

.no-outline {
  outline: none !important;
}

// font weight
.font-weight-semibold {
  font-weight: 600;
}

// backgrounds
.mild-background {
  background-color: #f7f8fc;
}

// cursor
.cursor-pointer {
  cursor: pointer;
}

// rotate
.rotate-180 {
  transform: rotate(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.border-bottom-grey {
  border-bottom: 1px solid rgba(0, 36, 156, 0.2);
}

.overflow-visible {
  overflow: visible;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.z-index-2 {
  z-index: 2;
}

.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.border-primary {
  border: 1px solid red;
}

.no-text-decor {
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
}

.modalBackground { 
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(#00249C, 0.6);
  z-index: 99;
  top: 0;
  left: 0;
}

.no-pointer-event {
  pointer-events: none;
}

.rounded-white-border {
  border: 1px solid white;
  border-radius: 0.25rem;
}

.text-decor-underline {
  text-decoration: underline;
}
