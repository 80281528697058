.exploreModal, .day1RegisterModal, .timeupIndication {
  padding: 2.75rem 5.625rem;
  max-width: 37.5rem;
  border-radius: 0.5rem;

  .inputField, .textArea {
    border: 1px #BCBCBC solid !important;
    border-radius: 0.25rem !important;
    &:focus {
      outline-color: #002193;
    }
  }
  .input-error, .textAreaError {
    font-size: 0.75rem !important;
  }
  .textArea {
    margin-bottom: 0 !important;
  }
  .inputLabel, .textAreaLabel {
    color: #7B7B7B !important;
    font-size: 0.75rem;
  }

  .successImageWrapper {
    position: relative;
    width: 19.563rem;
    height: 11.438rem;
    .partyIcon {
      width: 100%;
      height: 100%;
    }
    .thumbsUpIcon {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.timeupIndication  {
  .timeupIcon {
    width: 9.188rem;
  }

  .clockIcon {
    width: 11rem;
  }
}

.toggleOptions {
  background-color: #002193;
  color: #fff;
  border-radius: 4px;
  padding: 2px;
  display: inline-flex;
  .toggleOption {
    border-radius: 4px;
    min-width: 8.75rem;
    min-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      background-color: #fff;
      color: #002193;
    }
  }
}

.footerWrapper {
  margin-left: -10px;
  .loadingWrapper {
    width: 10px;
    height: 10px;
    img {
      vertical-align: text-top;
    }
  }
  .submitBtn {
    &.disabled {
      pointer-events: none;
    }
  }
}

