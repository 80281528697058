.tableReportContainer {
  .reportBody {
    padding: 0 !important;
    .ag-root-wrapper {
      border: none !important;
    }
    .ag-header-viewport {
      background-color: #fff;
    }
  }
  .colFilter {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #109CF1;
    cursor: pointer;
    position: relative;
    .colsMenu {
      box-shadow: 0px 6px 18px rgb(0 0 0 / 10%);
      border: 1px solid #8989891f;
      position: absolute;
      top: 2rem;
      right: 0;
      z-index: 5;
      background-color: #fff;
      color: #4C5862;
      text-align: left;
      border-radius: 2px;
      .colName {
        padding: 0.5rem 3rem 0.5rem 1rem;
        position: relative;
        white-space: nowrap;
        i {
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
          color: #ccd3eb;
          &.visible {
            color: #109CF1;
          }
        }
      }
    }
  }
  .exportBtn {
    border: 1px solid #FFFFFF;
    border-radius: 0.25rem;
  }
}