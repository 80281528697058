// Bootstrap overrides

// theme overrides
$primary: #00249C;
$success: #00A638;
$danger: #FC5858;
$light: #fff;

// spacing overrides
$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 2.5),
  7: ($spacer * 3),
  8: ($spacer * 3.5),
  9: ($spacer * 4),
  10: ($spacer * 4.5),

  // special cases
  dot125: ($spacer * 0.125),
  dot375: ($spacer * 0.375),
  dot625: ($spacer * 0.625),
  dot75: ($spacer * 0.75),
  1dot125: ($spacer * 1.125),
  1dot25: ($spacer * 1.25),
  1dot375: ($spacer * 1.375),
  1dot875: ($spacer * 1.875),
  2dot25: ($spacer * 2.25),
  2dot5: ($spacer * 2.5),
  2dot75: ($spacer * 2.75),
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

@import 'bootstrap/scss/bootstrap';
  