$border-color: #8c8c8c;
$focus-border-color: #6b6b6b;
$error-color: #e55359;

.inputContainer {
  font-size: 1rem;
  position: relative;
  .inputField {
    font-size: 1rem;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 17px;
    color: #252733;
    border: none;

    &:focus {
      outline-color: $focus-border-color;
    }

    &.password-field {
      padding-right: 2em;
    }
  }
  
  .input-error {
    font-size: 0.8rem;
    position: absolute;
    bottom: 0px;
    left: 0;
    color: $error-color;
    padding-left: 2px;
    bottom: -2px;
  }

  .passwordIcon {
    position: absolute;
    right: 0.55em;
    // top: 50%;
    top: 2.5rem;
    transform: translateY(-50%);
    opacity: 0.7;
    cursor: pointer;
  }
}