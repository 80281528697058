$border-color: #8c8c8c;
$focus-border-color: #6b6b6b;
$error-color: #e55359;

.textAreaContainer {
  font-size: 1rem;
  position: relative;
  .textArea {
    font-size: 1rem;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 17px;
    color: #252733;
    border: none;
    resize: none;
    height: 7.5rem;

    &:focus {
      outline-color: $focus-border-color;
    }
  }
  
  .textAreaError {
    font-size: 0.8rem;
    position: absolute;
    bottom: 0px;
    left: 0;
    color: $error-color;
    padding-left: 2px;
    bottom: -2px;
  }
}