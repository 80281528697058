@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.comparitiveInsight {
  width: 10rem; // applied for xs ans sm
  @include media-breakpoint-up(md) { 
    width: 13.25rem;
  }
  .trendGraph {
    width: 3.125rem;
    height: 2.5rem;
  }
}
