.scrollhost::-webkit-scrollbar {
  display: none;
}

.scrollhost-container {
  position: relative;
  height: 100%;
}
.scrollhost {
  overflow: auto;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}

.scroll-bar {
  position: absolute;
  border-radius: 7px;
  background-color: transparent;

  .scroll-thumb {
    position: absolute;
    border-radius: 6px;
    opacity: 1;
    top: 0;
    background-color: #A9AAAC;
    z-index: 9;
  }

  &.scrollY {
    right: 0;
    top: 0;
    width: 10px;
    height: 100%;
    .scroll-thumb {
      margin-left: 1px;
      width: 6px;
      height: 20px;
    }
  }
  &.scrollX {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    .scroll-thumb {
      margin-top: 1px;
      height: 6px;
      width: 20px;
    }
  }
}
