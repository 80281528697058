@import '../../styles/mediaQuery';
// .logo {
//   width: 5em;
// }

// a {
//   color: white;
// }

// .navTab {
//   color: white;
// }

// .nav-tabs .nav-link {
//   border: none;
// }

// @media (min-width: 992px) {
// .navbar-expand-lg .navbar-nav .nav-link {
//     padding-right: 1.5rem;
//     padding-left: 1.5rem;
// }
// }

// .nav-tabs {
//   border-bottom: none;
// }

// .nav-tabs {
//   .nav-item.show .nav-link, .nav-link.active {
//     background-color: #00249C;
//     border-bottom: 2px solid #FFFFFF;
//   margin-bottom: -14px;
//   }
// }

// @media (max-width: 992px) {
//   .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
//     border: none;
//     margin-bottom: 0px;
//   }
// }

.navCustom {
  width: 100vw;
  background-color: #00249C;
  padding-left: 40px;
  justify-content: space-between;
  @include lg {
    height: 64px;
    padding: 4px 40px !important;
  }
  

  .navBrand {
    display: flex;
    align-items: flex-end;
    .logo {
      height: 41px;
      margin-right: 10px;
    }
    .brandName {
      font-size: 10px;
      margin-bottom: -3px;
      font-weight: bold;
      line-height: 13px;
      letter-spacing: 0.223881px;
    }
  }
  .navbar-collapse {
    .navLinks {
      .navLink {
        padding-left: 20px;
        padding-right: 20px;
        color: #fff;
        box-sizing: content-box;
        opacity: 0.8;
        position: relative;
        .dot {
          display: none;
          border-radius: 50%;
          position: absolute;
          width: 4px;
          height: 4px;
          background: white;
          left: 50%;
          bottom: 9px;
          transform: translateX(-50%);
        }
  
        &.active {
          font-weight: bold;
          // border-bottom: 4px solid white;
          opacity: 1;
          .dot {
            display: block;
          }

        }

        &.disabled {
          pointer-events: none;
        }
  
        &:hover {
          text-decoration: unset;
          opacity: 1;
        }
      }
    }
  
    @include lg {
      height: 100%;
      .navLinks {
        height: 100%;
        .navLink {
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
    }
  }
  .avatar {
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    color: #8b8b8b;
  }
  .settingsDropdown {
    min-width: 7rem;
    right: 0;
    top: calc(100% + 10px);
    z-index: 6;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 25%);
    .settingsOption {
      border-bottom: 1px solid #eaeaea;
      &:hover {
        background-color: rgba(0, 0, 0, 0.082);
      }
    }
  }
  .addUrlComponent {
    color: #05FFFF;
    &:hover {
      background-color: #011876;
    }
    .sfAccountSelectedText {
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 32px;
      line-height: 1rem;
    }  
  }

  .sfAccountDropdown {
    min-width: 16rem;
    top: calc(100% + 0px);
    z-index: 6;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 25%);
    @media (max-width: 488px){
      right: 1px;
    }
    .sfAccountHeader {
      border-bottom: 1px solid #eaeaea;
      .sfAccountHeaderText {
        color: #00249C;
      }
      .sfAccountHeaderAddLink {
        color: #109CF1;
      }
    }
    .sfAccountOption {
      border-bottom: 1px solid #eaeaea;
      &:hover {
        background-color:rgba(0, 0, 0, 0.082);
      }
    }
  } 
  .addAccountPopupContainer {
    @media (max-width: 650px){
      max-width: 100%;
      flex: 1;
    }
    .addAccountPopup {
      height: 100%;
      width: 100%;
      border-radius: 4px;
      box-shadow: 0px 2px 8px rgb(0 0 0 / 25%);
      .addAccountPopupHeader {
        border-bottom: 1px solid#CCD3EB;
        .addAccountPopupHeaderText {
          color:#252733;
        }
      }
      .inputComponent {
        width: 100%;
        .inputField {
          font-size: 1rem;
          border-radius: 4px;
          width: 100%;
          box-sizing: border-box;
          margin-bottom: 17px;
          border-color: #8c8c8c;
          border: 1px solid #9FA2B4;
        }
        .inputLabel {
          color: #7B7B7B !important;
        }      
      }
      .footer {
        bottom: 0px;
        width: 100%;
        text-align: end;
        border-top: 1px solid #CCD3EB;
      }
    }
  }
  input:focus {
    outline-color:  #6b6b6b !important;
  }

}
