.warehouse {
  width: 100vw !important;
  .header {
    background: linear-gradient(180deg, #011772 0%, #00249C 100%);
    .backButton {
      width: 1rem;
      height: 0.75rem;
    }
  }
  .filters {
    .userSelect, .warehouseSelect {
      width: 10rem;
      .custom-select__control { // user select override
        line-height: 1.125rem;
        min-height: 30px;
        .custom-select__indicators {
          .custom-select__indicator-separator {
            margin-top: 6px;
            margin-bottom: 6px;
          }
        }
      }
    }
  }
  .infoField {
    border-left: 2px solid #E0E6FF;
  }
}
