.dateRangePicker {
  background: linear-gradient(180deg, #FFFFFF 50%, #F8F8F8 68.75%);
  border: 1px solid #9FA2B4;
  border-radius: 4px;
  padding: 0.5rem;
  color: #252733;
  cursor: pointer;
  font-size: 0.875rem;
  .disabledToggler {
    opacity: 0.5;
    pointer-events: none;
  }
  .calendarIcon {
    filter: grayscale(100%);
  }
  &.active {
    border-color: #00249C !important;
    color: #00249C !important;
    box-shadow: none !important;
    .calendarIcon {
      filter: unset;
    }
  }
}

// overriding styles
.daterangepicker {
  .ranges {
    .active {
      background-color: #00249C !important;
    }
  }
  .drp-calendar {
    .calendar-table {
      .table-condensed {
        tr {
          td {
            &.active:not(&.off) {
              background-color: #00249C !important;
            }
            &.active:hover {
              background-color: #00249C !important;
            }
            &.in-range:not(&.off) {
              background-color: rgba($color: #00249C, $alpha: 0.06);
            }
            &.in-range:hover {
              background-color: #eee !important;
            }
          }
        }
      }
    }
  }
}
