.datepickerWrapper {
  background: linear-gradient(180deg, #FFFFFF 50%, #F8F8F8 68.75%);
  border: 1px solid #9FA2B4;
  border-radius: 4px;
  .datePicker {
    color: #252733;
  }
  .disabledToggler {
    opacity: 0.5;
    pointer-events: none;
  }
  .calenderIcon {
    filter: grayscale(100%);
  }
}
