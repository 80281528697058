.barTimeTrend, .lineTimeTrend {
  .chartOptions {
    margin-bottom: 1.25rem;
    .scaleToggler {
      background-color: #E0E6FF;
      padding: 2px;
      border-radius: 4px;
      .toggleBtn {
        border-radius: 4px;
        color: #252733;
        height: 1.5rem;
        width: 2rem;
        transition: width 0.15s;
          &.active {
            width: 3.75rem;
            background-color: #fff;
        }
      }
    }
  }
  .chartContainer {
    height: 100%;
    &.chartOptionEnabled {
      height: calc(100% - 1.5rem - 4px - 1.25rem);
    }
  }
  .customY1Label, .customYLabel {
    top: 50%;
    font-size: 0.75rem;
    color: #A8A9AD;
    pointer-events: none;
    .colorIndicator {
      height: 6px;
      width: 6px;
      margin-right: 4px;
      background-color: #FC7D58;
      border-radius: 50%;
    }
    .lineIndicator {
      height: 16px;
      width: 16px;
      margin-right: 4px;
      transform: rotate(90deg);
    }
  }
  .customYLabel {
    transform: translateX(-50%) rotate(-90deg);
    left: 0.5rem;
  }
  .customY1Label {
    transform: translateX(50%) rotate(-90deg);
    right: 0.5rem;
  }
  .customXLabel {
    left: 50%;
    font-size: 12px;
    transform: translateX(-50%);
    color: #A8A9AD;
    pointer-events: none;
    bottom: 0.5rem;
  }
}