.drilldownContainer {
  width: 100vw;
  .drilldownTabs {
    background: linear-gradient(180deg, #011772 0%, #00249C 100%);
    padding: 0 10%;
    .drilldownTab {
      border-bottom: 3px solid transparent;
      margin-bottom: 1px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
      &.active {
        opacity: 1;
        border-color: #fff;
      }
    }
  }
}
