.custom-select__control {
  background: linear-gradient(180deg, #FFFFFF 50%, #F8F8F8 68.75%);
  border: 1px solid #9FA2B4 !important;
  border-radius: 4px;
  outline: none !important;
  cursor: pointer !important;

  &.custom-select__control--menu-is-open {
    border-color: #1c469c !important;
    .custom-select__value-container, .customIndicator {
      color: #1c469c !important;
    }
    .selectIcon {
      filter: unset;
    }
  }

  .selectIcon {
    filter: grayscale(100%);
  }

  .custom-select__indicator-separator {
    opacity: 0;
  }

  .custom-select__value-container {
    color: #252733;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  
  .customIndicator {
    color: #252733;
    font-size: 0.875rem;
  }

  &.custom-select__control--menu-is-open {
    .customIndicator {
      transform: rotate(180deg);
    }
  }
}
.custom-select__menu {
  z-index: 99 !important;
  width: unset !important;
  font-size: 0.875rem;
  text-align: left;
  min-width: 100%;
}

.custom-select__option {
  cursor: pointer !important;
  &.custom-select__option--is-selected {
    background-color: #00249C !important;
    color: #fff;
  } 
}

.selectOption {
  border-bottom: 1px solid #E1E1E1;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.048);
  }
}

.applyBtn {
  border: 1px solid #FFFFFF;
  border-radius: 0.25rem;
  &.disabled  {
    opacity: 0.5;
    pointer-events: none;
    background-color: #BFBFBF !important;
  }
}

.customMenu {
  border-bottom: 1px solid rgba(0, 0, 0, 0.116);
  &:hover {
    background-color: #f0f8ff;
  }
}

.menuScroll {
  max-height: 400px;
}
