.loginContainer {
  background-image: url(../../assets/image/login-bg.png);
  background-position: center;
  width: 100vw;
  min-height: 100vh;
  .loginForm {
    width: 300px;
    .loginHeader {
      margin-bottom: 3.75rem;
    }
    .loginFooter {
      margin-top: 1.625rem;
      .submitBtn {
        border: 1px solid #FFFFFF;
        border-radius: 0.25rem;
        &.loading {
          outline: none;
          border: none;
          pointer-events: none;
          opacity: 0.7;
        }
      }
      .loaderWrapper {
        width: 1rem;
        height: 1rem;
        img {
          width: 100% !important;
          height: 100% !important;
          vertical-align: top;
        }
      }
    }
    .signUpLink {
      background-color: rgba(#fff, 0.2);
      border-radius: 0.25rem;
      border: 1px solid rgba(#fff, 0.3);
      &:hover {
        background-color: rgba(#fff, 0.225);
        border: 1px solid rgba(#fff, 0.3);
      }
    }
  }
}