.reportWrapper {
  height: 21.25rem;
  &.tableWrapper {
    height: 30rem;
  }
  &.topUsersReport {
    @media (max-width: 992px) {
      height: unset;
    }
    .donutOuterContainer {
      align-items: center;
      @media (min-width: 992px) and (max-width: 1049px) {
        font-size: 0.83rem;
        .donutChartWrapper {
          font-size: 0.75rem;
        }
      }
      @media (min-width: 1050px) and (max-width: 1139px) {
        font-size: 0.85rem;
        .donutChartWrapper {
          font-size: 0.8rem;
        }
      }
      @media (min-width: 1140px) and (max-width: 1199px) {
        font-size: 0.9rem;
      }
      @media (min-width: 1200px) and (max-width: 1300px) {
        font-size: 0.95rem;
      }
      .donutChartWrapper {
        width: 12.5em;
        height: 12.5em;
        .donutInfo {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .text {
            font-size: 0.875em;
          }
          .value {
            font-size: 1.5em;
          }
        }
      }
      .donutChartLegends {
        .tColumn {
          line-height: 1rem;
          white-space: nowrap;
          &.userName {
            max-width: 10em;
            min-width: 7.5em;
          }
          &.queriesCount {
            max-width: 7.5em;
            min-width: 4em;
          }
          &.queriesPercentage  {
            width: 6em;
          }
          .tHead {
            color: #A8A9AD;
            font-weight: 700;
            font-size: 0.75em;
            padding: 0.25em 0 0.25em 0.5em;
          }
          .tCell {
            font-size: 0.75em;
            color: #252733;
            padding: 0.375em 0 0.375rem 0.5em;
            &.userNameCell {
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 10em;
            }
          }
        }
      }
    }
  }
  &.queriesBiggerReport {
    .reportBody {
      padding: 0 !important;
      table {
        table-layout: fixed;
        margin-bottom: 1.875rem;
        th {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          position: relative;
          width: 25%;
          &:first-child {
            width: 20%
          }
          &:last-child {
            width: 30%;
          }
          &:not(:last-child) {
            &:after {
              content: "";
              display: block;
              position: absolute;
              height: 2rem;
              width: 1px;
              background-color: #E5E5E5;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
            }
          }
        }
        td {
          height: 2.5rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.insightContainer {
  background: linear-gradient(180deg, #011772 0%, #00249C 100%);

  .scroll-thumb {
    background-color: rgba(58, 98, 233, 1);
  }

  .carousalArrow {
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid #109CF1;
    border-radius: 50%;
    top: calc(50% - 1.25rem);
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
    background-color: rgba(2, 31, 138, 1);
    img {
      width: 9px;
      // height: 14px;
    }

    &.left {
      left: 2.5rem;
      transform: translateX(-50%);
    }
    &.right {
      right: 2.5rem;
      transform: translateX(50%);
    }
  }
  .carousalBatch {
    flex-basis: calc(100% - 2.25rem);
    flex-shrink: 0;
    &:last-child {
      .insightCard {
        &:last-child {
          border-right: none !important;
        }
      }
    }
  }
  .insightCard {
    width: 25%;
    border-right: 0.5px solid rgba(255, 255, 255, 0.1);
    min-width: 250px;
  }
}

.dollarSymbol { // position absolute is done with bootstrap class
  right: 3.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.costPerCreditInput {
  border-radius: 0.25rem;
  width: 4.5rem;
}

.userSelect {
  width: 10rem;
  .custom-select__control { // user select override
    line-height: 1.125rem;
    min-height: 30px;
    .custom-select__indicators {
      .custom-select__indicator-separator {
        margin-top: 6px;
        margin-bottom: 6px;
      }
    }
  }
}

.filterLabel {
  color: #6A707E;
}

.filterInputBorder {
  border: 1px solid #9FA2B4;
  border-radius: 0.25rem;
}

.filters {
  border-bottom: 1px solid rgba(0, 36, 156, 0.2);
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 5;
}

.performanceTab {
  line-height: 1.25rem;
  padding: 5px 25px 7px;
  border-radius: 1rem;
  border: 1px solid #00249C;
  color: #4C5862;
  &:not(&.active) {
    border-color: transparent;
  }
  &.active {
    border-color:#00249C;
    background-color: #fff;
  }
}


.loadingComparitivInsight {
  min-height: 9.75rem;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: transparent;
  &.active {
    background-color: #00249C;
  }
}
